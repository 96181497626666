<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>ชื่อ Living Tips </span><span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ชื่อ Living Tips" 
                rules="required">
                <vs-input 
                  v-model="formData.title" 
                  class="w-full" 
                  name="title" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>กำหนด URL พิเศษ (ห้ามใส่ อัขระพิเศษ)</span>
              <span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Url พิเศษ" 
                rules="required|special_charecter"
              >
                <vs-input 
                  v-model="formData.custom_slug" 
                  :disabled="currentLang != 'th'"
                  class="w-full" 
                  name="slug" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>เลือกประเภทของ Living Tips </span><span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ประเภท" 
                rules="required">
                <div>
                  <vs-select
                    v-model="formData.sub_category_id"
                    name="ประเภท"
                    class="selectExample"
                    icon="arrow_downward"
                    placeholder="เลือกประเภทของ Living Tips"
                    autocomplete
                  >
                    <vs-select-item
                      v-for="(item, index) in categories.data"
                      :value="item.id"
                      :text="item.name"
                      :key="index"
                    />
                  </vs-select>
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รายละเอียดของ Living Tips (ใส่ข้อความไม่เกิน 200 คำ) </span>
              <span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="รายละเอียด" 
                rules="required">
                <div>
                  <vs-textarea 
                    v-model="formData.description" 
                    class="w-full" 
                    rows="8"
                    :maxlength="200"
                  />
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รูปภาพ </span>
              <span class="text-danger">*</span>
              <br >
              <span class="text-warning text-sm">นามสกุล .jpg / .png / .gif</span>
              <br >
              <span class="text-warning text-sm">(ขนาดรูปต่ำสุด 353 x 240 px -
ขนาดรูปสูงสุด 1920 x 1080 px)</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="รูปภาพ"
                rules="required|lh_mimes:jpg,png|lh_image_min_width:353|lh_image_max_width:1920|lh_image_min_height:240|lh_image_max_height:1080"
              >
                <ImageUpload
                  :folder="`${baseFolder}/living-tip/${formData.folder_name}/Image`"
                  v-model="formData.thumbnail"
                  selection-name="thumbnail"
                  has-delete-button
                  has-alt-text
                  crop="scale"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{
                    errors[0]
                  }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout class="flex flex-col">
            <div class="vx-col w-full md:w-6/12 py-2">
              <span>Content</span>
              <p class="text-warning">**Support การ Upload Video ขนาดไม่เกิน 98MB</p>
            </div>
            <div class="vx-col w-full">
              <div class="content-editor">
                  <Wyswyg                     
                    :folder="`${baseFolder}/living-tip/${formData.folder_name}/wyswyg`"
                    v-model="formData.content" />
              </div>
            </div>
          </FormLayout>

          <FormLayout>
            <FormLabel>
              <span>วันที่เผยแพร่ </span>
              <span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <div class="flex flex-col md:flex-row md:-mx-2">
                <div class="w-full mb-2 md:w-1/2 md:px-2">
                  <ValidationProvider 
                    v-slot="{ errors }" 
                    name="วันที่เผยแพร่" 
                    rules="required">
                    <datepicker
                      v-model="formData.published_date"
                      :highlighted="{
                        dates: [
                          // Highlight an array of dates
                          formData.published_date
                            ? new Date(formData.published_date)
                            : new Date(),
                        ],
                      }"
                      :disabled-dates="{
                        to: new Date(useDatepicker.moment().subtract(1, 'day'))
                      }"
                      :minimum-view="'day'"
                      :maximum-view="'year'"
                      format="dd MMMM yyyy"
                      placeholder="วันที่เผยแพร่"
                      typeable 
                    />
                    <span 
                      v-if="errors.length > 0" 
                      class="text-danger text-sm">
                      {{
                        errors[0]
                      }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card 
          class="mb-base" 
          title="SEO">
          <FormLayout>
            <FormLabel>
              <span>SEO Title </span>
              <span class="text-danger">*</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 160 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="SEO Title"
                rules="required|max:160"
              >
                <vs-input 
                  v-model="formData.seo_title" 
                  class="w-full" 
                  name="seo_title"
                  :maxlength="160"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO H1</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 255 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="SEO H1"
                rules="max:255"
              >
                <vs-input 
                  v-model="formData.seo_h1" 
                  class="w-full" 
                  name="seo_h1"
                  :maxlength="255"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO Description </span>
              <span class="text-danger">*</span>
              <br >
              <span class="text-warning text-sm">กรอกได้ไม่เกิน 300 ตัวอักษร</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="SEO Description" 
                rules="required|max:300">
                <vs-textarea
                  v-model="formData.seo_description"
                  class="w-full"
                  name="seo_description"
                  rows="6"
                  :maxlength="300"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO Keywords </span>
              <span class="text-danger">*</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="SEO Keywords"
                rules="required"
              >
                <vs-textarea 
                  v-model="formData.seo_keywords" 
                  class="w-full" 
                  name="seo_keywords" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>SEO Thumbnail </span>
              <br >
              <span class="text-warning text-sm">นามสกุล .jpg / .png / .gif</span>
              <br >
              <span class="text-warning text-sm">ขนาดรูปแนะนำ 600 x 338 px</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="SEO Thumbnail"
                rules="lh_mimes:jpg,png|lh_image_resolution:600,338"
              >
                <ImageUpload
                  :folder="`${baseFolder}/living-tip/${formData.folder_name}/Seo Thumbnail`"
                  v-model="formData.seo_image"
                  selection-name="seo_image"
                  has-delete-button
                  has-alt-text
                  crop="scale"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{
                    errors[0]
                  }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel/>
            <FormContent>
              <vs-button 
                class="w-1/3 mr-4" 
                @click="submit(context)"
              >
                {{ formData.id ? 'Update' : 'Submit' }}
              </vs-button>
              <vs-button 
                v-if="formData.id !== null" 
                @click="preview()"
              >
                Preview
              </vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { onMounted, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import Datepicker from 'vuejs-datepicker'
import env from '@/env'
import ImageUpload from '@/components/upload/ImageUpload'
import useDatepicker from '@/use/useDatepicker'
import useLivingTipUtil from '@/use/useLivingTipUtil'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import omit from 'lodash/omit'
import Wyswyg from '@/components/Wyswyg'

export default {
  name: 'LivingTipForm',
  components: {
    ImageUpload,
    Datepicker,
    ValidationObserver,
    Wyswyg
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const {
      initialData,
      formData,
      categories,
      fetchByRouteId,
      fetchSubCategoryList,
    } = useLivingTipUtil(ctx)
    const crudForm = useCrud(ctx, 'livingTip', {
      fetchOnMount: false
    })
    crudForm.routePrefix.value = 'lh-content-living-tip'

    onMounted(async () => {
      await fetchSubCategoryList()
      fetchByRouteId()
    })

    const updateItem = ({ validate }) => {
      validate().then((result) => {
        if (!result) {
          return
        }
        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        const updated_by = email

        // add user created by
        if (!created_by) {
          created_by = email
        }

        $store
          .dispatch('livingTip/updateItem', { initialData,
            formData: {
              ...omit(formData, ['updated_at']),
              created_by,
              updated_by
            }
          })
          .then(() => {
            $router.push({ name: 'lh-content-living-tip' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            if (
              error.message.split('unique constraint "content_slug_lang_key"').length > 1
            ) {
              notifyError({
                text:
                  'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากชื่อ หรือ URL พิเศษซ้ำกับข้อมูลที่มีอยู่ในระบบ',
              })
            } else {
              notifyError({ text: JSON.stringify(error) })
            }
          })
      })
    }

    const submit = (ctx) => {
      formData.title = formData.title.trim()
      formData.slug = isEmpty(formData.custom_slug)
        ? formData.title.replace(/[!@#$%^&*)(+=.?]/g, "").trim().split(' ').join('-')
        : formData.custom_slug.replace(/[!@#$%^&*)(+=.?]/g, "").trim().split(' ').join('-')

      formData.custom_slug = formData.slug

      if (formData.id) {
        updateItem(ctx)
      } else {
        crudForm.addItem(ctx, formData)
      }
    }

    const currentLang = computed(() => $store.state.locale.currentLang)

    const preview = () => {
      const lang = $store.state.locale.currentLang
      const path = get(formData, 'slug')
      window.open(`${env.VUE_APP_WEBSITE_URL}/${lang}/lh-living-concept/tips/${path}`)
    }

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)

    return {
      ...crudForm,
      updateItem,
      formData,
      categories,
      submit,
      preview,
      useDatepicker: useDatepicker(),
      baseFolder,
      currentLang
    }
  },
}
</script>
