var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(context){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(context)}}},[_c('vx-card',{staticClass:"mb-base"},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชื่อ Living Tips ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ชื่อ Living Tips","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"title"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("กำหนด URL พิเศษ (ห้ามใส่ อัขระพิเศษ)")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Url พิเศษ","rules":"required|special_charecter"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"disabled":_vm.currentLang != 'th',"name":"slug"},model:{value:(_vm.formData.custom_slug),callback:function ($$v) {_vm.$set(_vm.formData, "custom_slug", $$v)},expression:"formData.custom_slug"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("เลือกประเภทของ Living Tips ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ประเภท","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('vs-select',{staticClass:"selectExample",attrs:{"name":"ประเภท","icon":"arrow_downward","placeholder":"เลือกประเภทของ Living Tips","autocomplete":""},model:{value:(_vm.formData.sub_category_id),callback:function ($$v) {_vm.$set(_vm.formData, "sub_category_id", $$v)},expression:"formData.sub_category_id"}},_vm._l((_vm.categories.data),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.name}})}),1),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("รายละเอียดของ Living Tips (ใส่ข้อความไม่เกิน 200 คำ) ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"รายละเอียด","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('vs-textarea',{staticClass:"w-full",attrs:{"rows":"8","maxlength":200},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("รูปภาพ ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("นามสกุล .jpg / .png / .gif")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("(ขนาดรูปต่ำสุด 353 x 240 px -\nขนาดรูปสูงสุด 1920 x 1080 px)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"รูปภาพ","rules":"required|lh_mimes:jpg,png|lh_image_min_width:353|lh_image_max_width:1920|lh_image_min_height:240|lh_image_max_height:1080"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/living-tip/" + (_vm.formData.folder_name) + "/Image"),"selection-name":"thumbnail","has-delete-button":"","has-alt-text":"","crop":"scale"},model:{value:(_vm.formData.thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "thumbnail", $$v)},expression:"formData.thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',{staticClass:"flex flex-col"},[_c('div',{staticClass:"vx-col w-full md:w-6/12 py-2"},[_c('span',[_vm._v("Content")]),_c('p',{staticClass:"text-warning"},[_vm._v("**Support การ Upload Video ขนาดไม่เกิน 98MB")])]),_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"content-editor"},[_c('Wyswyg',{attrs:{"folder":(_vm.baseFolder + "/living-tip/" + (_vm.formData.folder_name) + "/wyswyg")},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1)])]),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("วันที่เผยแพร่ ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('div',{staticClass:"flex flex-col md:flex-row md:-mx-2"},[_c('div',{staticClass:"w-full mb-2 md:w-1/2 md:px-2"},[_c('ValidationProvider',{attrs:{"name":"วันที่เผยแพร่","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datepicker',{attrs:{"highlighted":{
                        dates: [
                          // Highlight an array of dates
                          _vm.formData.published_date
                            ? new Date(_vm.formData.published_date)
                            : new Date() ],
                      },"disabled-dates":{
                        to: new Date(_vm.useDatepicker.moment().subtract(1, 'day'))
                      },"minimum-view":'day',"maximum-view":'year',"format":"dd MMMM yyyy","placeholder":"วันที่เผยแพร่","typeable":""},model:{value:(_vm.formData.published_date),callback:function ($$v) {_vm.$set(_vm.formData, "published_date", $$v)},expression:"formData.published_date"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                      "+_vm._s(errors[0])+"\n                    ")]):_vm._e()]}}],null,true)})],1)])])],1)],1),_c('vx-card',{staticClass:"mb-base",attrs:{"title":"SEO"}},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("SEO Title ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("กรอกได้ไม่เกิน 160 ตัวอักษร")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO Title","rules":"required|max:160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"seo_title","maxlength":160},model:{value:(_vm.formData.seo_title),callback:function ($$v) {_vm.$set(_vm.formData, "seo_title", $$v)},expression:"formData.seo_title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("SEO H1")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("กรอกได้ไม่เกิน 255 ตัวอักษร")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO H1","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"seo_h1","maxlength":255},model:{value:(_vm.formData.seo_h1),callback:function ($$v) {_vm.$set(_vm.formData, "seo_h1", $$v)},expression:"formData.seo_h1"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("SEO Description ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("กรอกได้ไม่เกิน 300 ตัวอักษร")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO Description","rules":"required|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"name":"seo_description","rows":"6","maxlength":300},model:{value:(_vm.formData.seo_description),callback:function ($$v) {_vm.$set(_vm.formData, "seo_description", $$v)},expression:"formData.seo_description"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("SEO Keywords ")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO Keywords","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"name":"seo_keywords"},model:{value:(_vm.formData.seo_keywords),callback:function ($$v) {_vm.$set(_vm.formData, "seo_keywords", $$v)},expression:"formData.seo_keywords"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("SEO Thumbnail ")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("นามสกุล .jpg / .png / .gif")]),_c('br'),_c('span',{staticClass:"text-warning text-sm"},[_vm._v("ขนาดรูปแนะนำ 600 x 338 px")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"SEO Thumbnail","rules":"lh_mimes:jpg,png|lh_image_resolution:600,338"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/living-tip/" + (_vm.formData.folder_name) + "/Seo Thumbnail"),"selection-name":"seo_image","has-delete-button":"","has-alt-text":"","crop":"scale"},model:{value:(_vm.formData.seo_image),callback:function ($$v) {_vm.$set(_vm.formData, "seo_image", $$v)},expression:"formData.seo_image"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                  "+_vm._s(errors[0])+"\n                ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('vx-card',{staticClass:"mb-base"},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดโดย")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formData.updated_by))])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดวันที่")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.formData.updated_at)))])])],1),_c('FormLayout',[_c('FormLabel'),_c('FormContent',[_c('vs-button',{staticClass:"w-1/3 mr-4",on:{"click":function($event){return _vm.submit(context)}}},[_vm._v("\n                "+_vm._s(_vm.formData.id ? 'Update' : 'Submit')+"\n              ")]),(_vm.formData.id !== null)?_c('vs-button',{on:{"click":function($event){return _vm.preview()}}},[_vm._v("\n                Preview\n              ")]):_vm._e()],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }